import moment from 'moment'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { REPORTS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  total: {
    status: 'loading',
    data: 0,
  },
  new_contact_overtime: {
    status: 'loading',
    data: [],
  },
  new_contact: {
    status: 'loading',
    data: 0,
  },
  across_time_day: {
    status: 'loading',
    data: [],
  },
  across_time_hour: {
    status: 'loading',
    data: [],
  },
  new_trends_daily: {
    status: 'loading',
    data: {
      comparison: {
        current: 0,
        last_time: 0,
        percent: 0,
      },
      result: [],
    },
  },
  new_trends_weekly: {
    status: 'loading',
    data: {
      comparison: {
        current: 0,
        last_time: 0,
        percent: 0,
      },
      result: [],
    },
  },
  new_trends_monthly: {
    status: 'loading',
    data: {
      comparison: {
        current: 0,
        last_time: 0,
        percent: 0,
      },
      result: [],
    },
  },
  new_trends_quarterly: {
    status: 'loading',
    data: {
      comparison: {
        current: 0,
        last_time: 0,
        percent: 0,
      },
      result: [],
    },
  },
})

export const getters = {
  getField,
}

export const actions = {
  exportReportData({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/DISPLAY_TOAST',
        { message: 'Download will start automatically..' },
        { root: true }
      )
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/contact/${params.type}/export`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, 'my_data.csv')
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                let filename = `report_${params.type}`
                if (params.parameter.time_offsets !== undefined)
                  filename =
                    filename + `_timezone-${params.parameter.time_offsets}`
                if (params.parameter.channel)
                  filename = filename + `_channel-${params.parameter.channel}`
                filename = filename + `_${moment().format('DD-MMM-YYYY_HH:mm')}`
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename.toUpperCase())
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to get data. Please try again later' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getReportDataMaster({ commit }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(`${REPORTS_URL}/contact/${params.type}`, params.parameter, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              `UPDATE_GENERAL_${params.type
                .toUpperCase()
                .replace(/-/g, '_')
                .replace(/\//g, '_')}_REPORT`,
              res
            )
            if (params.withMeta)
              commit(
                'layouts/SET_META',
                {
                  pagination: {
                    cursor: {},
                    limit: 6,
                    offset: 1,
                    total: res.data.length,
                  },
                },
                { root: true }
              )
            resolve(true)
          },
          (err) => {
            commit(`UPDATE_REPORT_STATUS`, {
              res: err,
              type: `${params.type.replace(/-/g, '_').replace(/\//g, '_')}`,
            })
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_GENERAL_TOTAL_REPORT(state, payload) {
    state.total.data = payload.data[0].total
    state.total.status = payload.status
  },
  UPDATE_GENERAL_NEW_CONTACT_OVERTIME_REPORT(state, payload) {
    const data = payload.data.sort(
      (left, right) =>
        moment(left.date).format('YYYYMMDD') -
        moment(right.date).format('YYYYMMDD')
    )
    state.new_contact_overtime.data = data
    state.new_contact_overtime.status = payload.status
  },
  UPDATE_GENERAL_NEW_CONTACT_REPORT(state, payload) {
    state.new_contact.data = payload.data.total
    state.new_contact.status = payload.status
  },
  UPDATE_GENERAL_NEW_TRENDS_DAILY_REPORT(state, payload) {
    if (payload.data.comparison) state.new_trends_daily.data = payload.data
    else
      state.new_trends_daily.data = {
        comparison: {
          current: 0,
          last_time: 0,
          percent: 0,
        },
        result: [],
      }
    state.new_trends_daily.status = payload.status
  },
  UPDATE_GENERAL_NEW_TRENDS_WEEKLY_REPORT(state, payload) {
    if (payload.data.comparison) state.new_trends_weekly.data = payload.data
    else
      state.new_trends_weekly.data = {
        comparison: {
          current: 0,
          last_time: 0,
          percent: 0,
        },
        result: [],
      }
    state.new_trends_weekly.status = payload.status
  },
  UPDATE_GENERAL_NEW_TRENDS_MONTHLY_REPORT(state, payload) {
    if (payload.data.comparison) state.new_trends_monthly.data = payload.data
    else
      state.new_trends_monthly.data = {
        comparison: {
          current: 0,
          last_time: 0,
          percent: 0,
        },
        result: [],
      }
    state.new_trends_monthly.status = payload.status
  },
  UPDATE_GENERAL_NEW_TRENDS_QUARTERLY_REPORT(state, payload) {
    if (payload.data.comparison) state.new_trends_quarterly.data = payload.data
    else
      state.new_trends_quarterly.data = {
        comparison: {
          current: 0,
          last_time: 0,
          percent: 0,
        },
        result: [],
      }
    state.new_trends_quarterly.status = payload.status
  },
  UPDATE_GENERAL_ACROSS_TIME_DAY_REPORT(state, payload) {
    state.across_time_day.data = payload.data
    state.across_time_day.status = payload.status
  },
  UPDATE_GENERAL_ACROSS_TIME_HOUR_REPORT(state, payload) {
    state.across_time_hour.data = payload.data
    state.across_time_hour.status = payload.status
  },
  UPDATE_REPORT_STATUS(state, payload) {
    state[payload.type].status = payload.res.status
  },
}
