import { getField } from 'vuex-map-fields'

export const state = () => ({
  channelLists: [
    {
      id: 0,
      name: 'All channels',
      code: 'all',
    },
    {
      id: 1,
      name: 'Whatsapp',
      code: 'wa',
    },
    {
      id: 2,
      name: 'Whatsapp cloud',
      code: 'wa_cloud',
    },
    {
      id: 3,
      name: 'Telegram',
      code: 'telegram',
    },
    {
      id: 4,
      name: 'Facebook',
      code: 'fb',
    },
    {
      id: 5,
      name: 'Livechat widget',
      code: 'livechat_dot_com',
    },
    {
      id: 6,
      name: 'Instagram',
      code: 'ig',
    },
    {
      id: 7,
      name: 'Email',
      code: 'email',
    },
    {
      id: 8,
      name: 'Line messenger',
      code: 'line',
    },
    {
      id: 9,
      name: 'Twitter',
      code: 'twitter',
    },
    {
      id: 10,
      name: 'Webchat',
      code: 'web_chat',
    },
    {
      id: 11,
      name: 'Instagram comment',
      code: 'ig_comment',
    },
    {
      id: 12,
      name: 'Mobile chat',
      code: 'app_chat',
    },
  ],
})

export const getters = {
  getField,
}

export const actions = {}
