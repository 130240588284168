import moment from 'moment'
import _ from 'lodash-core'
import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { REPORTS_URL, REPORTS_URL_V1_1 } from '~/assets/variables/endpoints'

export const state = () => ({
  agents: [
    {
      name: 'Online',
      alias: 'online',
      count: 0,
      isLoading: true,
      showing: 'all online agent today',
    },
    {
      name: 'Away',
      alias: 'away',
      count: 0,
      isLoading: true,
      showing: 'all agent away today',
    },
    {
      name: 'Offline',
      alias: 'offline',
      count: 0,
      isLoading: true,
      showing: 'all offline agent today',
    },
  ],
  speed: {
    status: 'loading',
    data: {
      organization_id: null,
      first_response: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      response_time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      resolution_time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      wait_time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
    },
  },
  ratedAgents: [
    {
      full_name: 'Agent Qontak',
      email: 'agent@qontak.com',
      rated_chats: {
        total: 98,
        very_satisfied: 50,
        satisfied: 42,
        neutral: 5,
        dissatisfied: 1,
        very_dissatisfied: 0,
        score: 93.87,
      },
    },
    {
      full_name: 'Agent Dua Qontak',
      email: 'agent2@qontak.com',
      rated_chats: {
        total: 160,
        very_satisfied: 104,
        satisfied: 42,
        neutral: 14,
        dissatisfied: 0,
        very_dissatisfied: 0,
        score: 87.9,
      },
    },
    {
      full_name: 'Agent Tiga Qontak',
      email: 'agent3@qontak.com',
      rated_chats: {
        total: 50,
        very_satisfied: 33,
        satisfied: 10,
        neutral: 5,
        dissatisfied: 0,
        very_dissatisfied: 2,
        score: 69.7,
      },
    },
  ],
  inbox: {
    status: 'loading',
    data: {
      organization_id: null,
      total: 0,
      resolved: 0,
      assigned: 0,
      unassigned: 0,
      campaign: 0,
    },
  },
  mau: {
    status: 'loading',
    data: {
      total: 0,
    },
  },
  total_agent_supervisor: {
    status: 'loading',
    data: {
      organization_id: null,
      total: 0,
      agent: {
        total: 0,
        percent: 0,
      },
      supervisor: {
        total: 0,
        percent: 0,
      },
    },
  },
  all_time_historical_mau: {
    status: 'loading',
    data: {
      time: [],
      count: [],
    },
  },
  tag: {
    status: 'loading',
    data: {
      name: [],
      count: [],
    },
  },
  conversation_log: {
    status: 'loading',
    data: [],
    pagination: {
      offset: 1,
      limit: 7,
      total: 0,
    },
    query: '',
  },
  new_room_hour: {
    status: 'loading',
    data: [],
  },
  new_room_day: {
    status: 'loading',
    data: [],
  },
  average_first_response: {
    status: 'loading',
    data: [],
  },
  average_waiting_time: {
    status: 'loading',
    data: [],
  },
  average_resolution_time: {
    status: 'loading',
    data: [],
  },
})

export const getters = {
  getField,
}

export const actions = {
  exportReportData({ commit }, params) {
    return new Promise((resolve, reject) => {
      commit(
        'layouts/DISPLAY_TOAST',
        { message: 'Download will start automatically..' },
        { root: true }
      )
      let endpointUrl = REPORTS_URL
      if (
        params.type === 'tag' ||
        params.type === 'conversation-log' ||
        params.type === 'average-first-response'
      )
        endpointUrl = REPORTS_URL_V1_1
      requests.whatsapp
        .getService(
          `${endpointUrl}/${params.path}${params.type}/export`,
          params.parameter,
          {
            Authorization: this.$auth.getToken('hub'),
          }
        )
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'text/csv;charset=utf-8;' })
            if (navigator.msSaveBlob) {
              navigator.msSaveBlob(blob, 'my_data.csv')
            } else {
              const link = document.createElement('a')
              if (link.download !== undefined) {
                let filename = `report_${params.type}`
                if (params.parameter.time_offsets !== undefined)
                  filename =
                    filename + `_timezone-${params.parameter.time_offsets}`
                if (params.parameter.channel)
                  filename = filename + `_channel-${params.parameter.channel}`
                filename = filename + `_${moment().format('DD-MMM-YYYY_HH:mm')}`
                const url = URL.createObjectURL(blob)
                link.setAttribute('href', url)
                link.setAttribute('download', filename.toUpperCase())
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
              }
            }
            resolve(true)
          },
          (err) => {
            commit(
              'layouts/DISPLAY_TOAST',
              { message: 'Failed to get data. Please try again later' },
              { root: true }
            )
            reject(err)
          }
        )
    })
  },
  getReportDataMaster({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      const isCounted = params.parameter.offset === 1
      const sentParams = {
        query: state[`${params.type.replace(/-/g, '_')}`].query,
        is_counted: isCounted,
        ...params.parameter,
      }
      let endpointUrl = REPORTS_URL
      if (
        params.type === 'tag' ||
        params.type === 'conversation-log' ||
        params.type === 'average-first-response'
      )
        endpointUrl = REPORTS_URL_V1_1
      requests.whatsapp
        .getService(`${endpointUrl}/${params.path}${params.type}`, sentParams, {
          Authorization: this.$auth.getToken('hub'),
        })
        .subscribe(
          (res) => {
            commit(
              `UPDATE_GENERAL_${params.type
                .toUpperCase()
                .replace(/-/g, '_')}_REPORT`,
              { ...res, isCounted, query: sentParams.query }
            )
            resolve(true)
          },
          (err) => {
            commit(`UPDATE_REPORT_STATUS`, {
              res: err,
              type: `${params.type.replace(/-/g, '_')}`,
            })
            reject(err)
          }
        )
    })
  },
  getReportUnassignedRoom({ commit, state }, params) {
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/general/unassigned/download-from-email`,
          params.parameter,
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'success',
                message: res.data,
                item: '',
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(res)
          },
          (err) => {
            commit(
              'layouts/UPDATE_NOTIFICATION',
              {
                display: true,
                type: 'failed',
                message: 'Error!',
                item: err.error.messages[0],
                callback: {
                  text: 'OK',
                  method: 'closeNotification',
                },
              },
              { root: true }
            )
            resolve(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_GENERAL_SPEED_REPORT(state, payload) {
    state.speed.data = payload.data
    state.speed.status = payload.status
  },
  UPDATE_GENERAL_INBOX_REPORT(state, payload) {
    state.inbox.data = payload.data
    state.inbox.status = payload.status
  },
  UPDATE_GENERAL_MAU_REPORT(state, payload) {
    state.mau.data.total = payload.data.total
    state.mau.status = payload.status
  },
  UPDATE_GENERAL_TOTAL_AGENT_SUPERVISOR_REPORT(state, payload) {
    state.total_agent_supervisor.data = payload.data
    state.total_agent_supervisor.status = payload.status
  },
  UPDATE_GENERAL_TAG_REPORT(state, payload) {
    state.tag.data = {
      name: [],
      count: [],
    }
    payload.data.forEach((data, i) => {
      state.tag.data.name.push(data.name)
      state.tag.data.count.push(data.count)
    })
    state.tag.status = payload.status
  },
  UPDATE_GENERAL_ALL_TIME_HISTORICAL_MAU_REPORT(state, payload) {
    state.all_time_historical_mau.data = {
      time: [],
      count: [],
    }
    payload.data.forEach((data) => {
      const month = data.time.split(', ')[0]
      state.all_time_historical_mau.data.time.push(
        `${month.slice(0, 3)} ${data.time.split(', ')[1]}`
      )
      state.all_time_historical_mau.data.count.push(data.count)
    })
    state.all_time_historical_mau.status = 'success'
  },
  UPDATE_GENERAL_CONVERSATION_LOG_REPORT(state, payload) {
    state.conversation_log.data = payload.data.response
    state.conversation_log.status = payload.status
    state.conversation_log.query = payload.query

    if (payload.isCounted) {
      state.conversation_log.pagination = payload.data.pagination
    } else {
      state.conversation_log.pagination = {
        ...state.conversation_log.pagination,
        offset: payload.data.pagination.offset,
        limit: payload.data.pagination.limit,
      }
    }
  },
  UPDATE_GENERAL_NEW_ROOM_HOUR_REPORT(state, payload) {
    state.new_room_hour.data = payload.data
    state.new_room_hour.status = payload.status
  },
  UPDATE_GENERAL_NEW_ROOM_DAY_REPORT(state, payload) {
    const days = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ]
    const sortedData = []
    payload.data.forEach((data) => {
      data.index = days.indexOf(data.day)
      sortedData.push(data)
    })
    state.new_room_day.data = _.sortBy(sortedData, (c) => c.index)
    state.new_room_day.status = payload.status
  },
  UPDATE_GENERAL_AVERAGE_FIRST_RESPONSE_REPORT(state, payload) {
    state.average_first_response.data = payload.data
    state.average_first_response.status = payload.status
  },
  UPDATE_GENERAL_AVERAGE_WAITING_TIME_REPORT(state, payload) {
    state.average_waiting_time.data = payload.data
    state.average_waiting_time.status = payload.status
  },
  UPDATE_GENERAL_AVERAGE_RESOLUTION_TIME_REPORT(state, payload) {
    state.average_resolution_time.data = payload.data
    state.average_resolution_time.status = payload.status
  },
  UPDATE_REPORT_STATUS(state, payload) {
    state[payload.type].status = payload.res.status
    state[payload.type].error = payload.res?.error?.messages[0] ?? ''
  },
}
